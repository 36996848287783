import React from "react";

import GlobalContext from "../../GlobalContext";

import './Citation.scss';

function Citation(props){
  
  const global = React.useContext(GlobalContext);

  const { citation } = props;

  if(!(citation in global.state.citations)) return false;

  const { title, url, description } = global.state.citations[citation];

  return (
    <div className='content citation'>
      <h2>{ title }</h2>
      <p>{ description }</p>
      <a 
        href={ url } 
        target='_blank' 
        rel='noreferrer noopener'
        >{ url }</a>
    </div>
  )
}

export default Citation;

