import React from "react";
import DOMPurify from 'dompurify';

import Citations from "../../Citations/Citations";
import GlobalContext from "../../GlobalContext";
import MessageTime from "../MessageTime/MessageTime";

import './MessageText.scss';

function MessageText(props){
  const global = React.useContext(GlobalContext);

  const citations = global.state.citationsHandling !== 'off' ?
    <Citations 
      citations={ props.citations } 
      numShow={ props.numShow }
      /> :
    false;

  return (
    <div className='message'>
      { typeof props.content === 'object' ? <div className='content'>{ props.content }</div> : <div className='content' dangerouslySetInnerHTML={{ __html:DOMPurify.sanitize(props.content, { ADD_ATTR: ['target'] }) }} /> }
      {citations}
      <MessageTime datetime={ props.datetime } />
    </div>
  );
}

export default MessageText;