import React from "react";

import './LoadingIndicator.scss';

function LoadingIndicator(){
  return (
    <div className="loading">
      <span></span>
      <span></span>
      <span></span>
    </div>
  );
}

export default LoadingIndicator;