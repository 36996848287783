import React from 'react';

import './CitationsNav.scss';

const label = 'Citations';
const maxCitations = 5;
const showCitations = 3;

function CitationsNav(props) {

  const { numShow, setNumShow } = props;

  let links = [];

  switch(true){
    case numShow === 0:
      links = [<button key='more' onClick={() => { setNumShow(showCitations) }}>Show More { label }</button>];
      break;
    case numShow === maxCitations:
      links = [
        <button key='less' onClick={() => { setNumShow(showCitations) }}>Show Less { label }</button>,
        <button key='hide' onClick={() => { setNumShow(0) }}>Hide { label }</button>
      ];
      break;
    default:
      links = [
        <button key='more' onClick={() => { setNumShow(maxCitations) }}>Show More { label }</button>,
        <button key='hide' onClick={() => { setNumShow(0) }}>Hide { label }</button>
      ];
  }

  return (
    <nav className='citations'>{ links }</nav>
  )
}

export default CitationsNav;