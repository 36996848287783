import React from "react";

import './DateTimeDivider.scss';

function DateTimeDivider(props){

  const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
  let datetime = props.datetime.toLocaleDateString(undefined, options)
    .split(',')
    .slice(0,2)
    .join(',');
  
  switch(props.datetime.getDate()){
    case 1:
      datetime += 'st';
      break;
    case 2:
      datetime += 'nd';
      break;
    case 3:
      datetime += 'rd';
      break;
    default:
      datetime += 'th';
  }

  return (
    <rlcb-datetime-divider key={'divider-' + props.datetime.getDate}>
      <rlcb-divider class='left' />
      <p>{datetime}</p>
      <rlcb-divider class='right' />
    </rlcb-datetime-divider>
  );
}

export default DateTimeDivider;