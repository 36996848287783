import React from "react";
import { FaRegTrashAlt } from 'react-icons/fa';

import GlobalContext from "../GlobalContext";

import './ClearConversation.scss';

export default function ClearConversation(){

  const global = React.useContext(GlobalContext);

  const clearConversation = () => {
    if(!global.state.loading) global.actions.clearConversation();
  }

  return (
    <button 
      id="rlcb-close"
      className={ global.state.loading ? 'disabled' : '' }
      disabled={ global.state.loading }
      onClick={ clearConversation }
      >
      <FaRegTrashAlt 
        size='1.5em'  
      />
    </button>
  );
}