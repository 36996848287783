import React from "react";
import Avatar from "../Avatar/Avatar";
import MessageText from "./MessageText/MessageText";

import './Message.scss'

function Message(props){
  return (
    <rlcb-message class={ props.from }>
      <Avatar type={ props.from } />
      <MessageText 
        citations={ props.citations } 
        content={ props.content } 
        datetime={ props.datetime }
        numShow={ props.numShow ? props.numShow : 0 }
      />
    </rlcb-message>
  );
}

export default Message;