import React from "react";
import { BsLightningFill } from "react-icons/bs";

import './PoweredByReachality.scss';

function PoweredByReachality(){
  return (
    <powered-by>
      <BsLightningFill/>
      by&nbsp;<span>Reachality</span>
    </powered-by>
  );
}

export default PoweredByReachality;