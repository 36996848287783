import React from "react";
import { FaUserAlt } from 'react-icons/fa';
import GlobalContext from "../GlobalContext";

import avatar from './../../images/avatar-42.png';
import './Avatar.scss'


function Avatar(props){
  const global = React.useContext(GlobalContext);

  const avatarSrc = global.state.avatar.src ? `/images/${global.state.avatar.src}` : avatar;
  const imageHeight = global.state.avatar.src ? 50 : 42;
  const image = props.type === 'user' ? <FaUserAlt size={27} /> : <img src={avatarSrc} height={imageHeight} width={imageHeight} alt='Lumina agent avatar' />;

  return (
    <rlcb-avatar>{image}</rlcb-avatar>
  );
}

export default Avatar;