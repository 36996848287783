import React from 'react';

import ChatBot from './ChatBot/ChatBot';
import GlobalContext from '../components/GlobalContext';
import Initializing from './Initializing/Initializing';

function BotContainer() {

  const global = React.useContext(GlobalContext);

  if(global.state.initialized){
    return <ChatBot />
  } else {
    return <Initializing />
  }
}

export default BotContainer;