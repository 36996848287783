import React from "react";

import GlobalContext from "../../GlobalContext";
import './MessageEntryError.scss'

function MessageEntryError(){

  const global = React.useContext(GlobalContext);

  return global.state.message === '' ? <p className='error'>Prompt is required</p> : false;
}

export default MessageEntryError;