import React, { useState } from "react";
import Citation from "./Citation/Citation";

import './Citations.scss';
import CitationsNav from "./CitationsNav/CitationsNav";

function Citations(props){

  const [ numShow, setNumShow ] = useState(props.numShow);

  if(typeof props.citations === 'undefined' || props.citations === null) return false;

  const citationsShown = numShow > 0 ? props.citations.slice(0,numShow) : [];

  const citations = citationsShown.map(citation => {
    return <Citation citation={ citation } />
  })

  return (
    <div className='citations'>
      { citations }
      <CitationsNav 
        numShow={ numShow } 
        setNumShow={ setNumShow }
        />
    </div>
  )
}

export default Citations;

