import React from 'react';
import GlobalContext from './GlobalContext';

const colorVars = {
  primary: '--rlcb-primary',
  primaryLight: '--rlcb-primary-light'
}

const StylesOverride = () => {
  const global = React.useContext(GlobalContext);

  let avatarOverride = '', colorsOverride = '';

  Object.keys(global.state.colors).forEach(key => {
    colorsOverride += `${colorVars[key]}:${global.state.colors[key]};`;
  });

  if('padding' in global.state.avatar){
    avatarOverride += `padding:${global.state.avatar.padding};`;
  }

  return (
    <style>{[
      `:root{${colorsOverride}}`, 
      `lumina-bot message-list .empty img {${avatarOverride}}`
    ]}</style>
  );
}

export default StylesOverride;