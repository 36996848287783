import React from 'react';

import './Initializing.scss';
import LoadingIndicator from '../../components/LoadingIndicator/LoadingIndicator';

function Initializing() {

  return (
    <lumina-bot class='initializing content'>
      <LoadingIndicator />
    </lumina-bot>
  );
}

export default Initializing;