import React from 'react';
import "iframe-resizer/js/iframeResizer.contentWindow";

import './App.scss';

import BotContainer from './views/BotContainer';
import GlobalState from './components/GlobalState';

function App() {
  return (
    <GlobalState>
      <BotContainer />
    </GlobalState>
  );
}

export default App;