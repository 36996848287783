import React from "react";

import ClearConversation from "../ClearConversation/ClearConversation";

import './ChatHeader.scss';

const ChatHeader = () => {

  return (
    <header>
      <ClearConversation />
    </header>
  );
}

export default ChatHeader;